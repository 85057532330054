import { MessageObject } from "../types/data";
import { getTokenRequest } from "../utils/api";

interface callbackFunc {
    (message: MessageObject): void
}
const READY_STATE = 1;
const host = location.host.includes('localhost') || location.host.includes('127.0.0.1') ? 'test.bi.datawake.cn' : location.host;
const wssURL = `wss://${host}/llm_server/ws/chat`;

/** 反馈错误码 */
export const FEEDBACK_ERROR_CODE = 2007;
/** token过期码 */
export const TOKEN_ERROR_CODE = 401;


/**
 * 判断是否为debug或warn消息，并打印在控制台，无需保存，不传递给回调函数
 * @param message 
 * @returns 
 */
const isDebugOrWarn = (message: MessageObject) => {
    if (message.type === 'debug') {
        console.log('d:', message.msg, '-d-', message.data); // 后端debug所需-勿删！！！
        return true;
    }
    if (message.type === 'warn') {
        console.warn('w:', message.msg, '-c:', message.code) // warn log 勿删
        return true;
    }
    return false;
}

class AiAnalyseSocket {
    public ws: WebSocket;
    private callback: callbackFunc;
    /** 重连次数 */
    private reconnectAttempts = 0;
    /** 最大重连次数 */
    private maxReconnectAttempts = 5;
    /** 当前消息 */
    private currentMessage: MessageObject;
    // private cache: Array<Object> = [];
    public constructor(token = '') {
        this.ws = new WebSocket(`${wssURL}?access_token=${token}`);
        this.initWebSocket(this.callback);
    }

    public initWebSocket(callback?: callbackFunc, message?: MessageObject) {
        if (callback) this.callback = callback;
        this.ws.onopen = () => {
            console.log("[socket message] websocket is opened");
            if (message) {
                // 重连后发送消息
                this.sendMessage(message);
            }
        }
        // 监听消息
        this.ws.onmessage = ({ data = null }) => {
            if (data) {
                try {
                    const messageData: MessageObject = JSON.parse(data);

                    if (messageData.type === 'file' && messageData.fileType === 'msg_json' && messageData.url) {
                        // 读取JSON文件，把文件内容作为消息发送
                        fetch(messageData.url).then(res => res.json()).then(res => {
                            res.forEach((item: MessageObject) => {
                                if (isDebugOrWarn(item)) {
                                    return;
                                }
                                this.callback(item);
                            });
                        }).catch(err => {
                            console.error('fetch-err', messageData, err);
                        });
                        return;
                    }
                    if (messageData.type === 'error' && messageData.code === TOKEN_ERROR_CODE) {
                        // token过期重连并重新发送消息
                        this.reconnect(this.currentMessage);
                    } else {
                        // 重连成功并收到非token过期消息后重置重连次数
                        this.reconnectAttempts = 0;
                    }
                    if (isDebugOrWarn(messageData)) {
                        return;
                    }
                    // 接收消息回调函数
                    this.callback(messageData);
                } catch (error) {
                    console.error("[socket message] onmessage error" + error);
                }
            }
        }
        this.ws.onerror = () => {
            this.reconnect();
            console.log("[socket message] websocket onerror");
        }
        this.ws.onclose = (e) => {
            console.log("[socket message] websocket onclose", e);
            if (e.reason === 'Python session closed') {
                // 异常关闭
                this.callback?.({ type: 'error', msg: 'websocket连接关闭', chatId: '' });
            }
        }
    }

    /**
     * 重连websocket，需要重新发送消息时传入message
     * @param message 重连的消息
     * @returns 
     */
    private reconnect(message?: MessageObject) {
        console.log("[socket message] websocket reconnect", message);
        if (this.reconnectAttempts >= this.maxReconnectAttempts) {
            // 超过重连次数
            return;
        }
        setTimeout(() => {
            getTokenRequest().then(token => {
                this.ws?.close?.();
                console.log("[socket message] websocket close then reconnect");
                this.ws = new WebSocket(`${wssURL}?access_token=${token}`);
                this.initWebSocket(this.callback, message);
            });
        }, (Math.pow(3, this.reconnectAttempts) - 1) * 200); // 指数退避策略
        this.reconnectAttempts += 1;
    }
    public sendMessage(message: MessageObject) {
        try {
            this.currentMessage = message;
            const sendData = JSON.stringify(message);
            if (this.ws.readyState === READY_STATE) {
                this.ws.send(sendData);
                return;
            } else {
                console.log("[socket message] websocket未初始化");
                this.reconnect(message);
            }
            // setTimeout(() => {
            //     this.ws.send(sendData);
            // }, 800);

        } catch (error) {
            console.error('[socket message] sendMessage error' + error);
            this.reconnect(message);
        }

    }

    public close() {
        console.log("[socket message] websocket close");
        this.ws?.close?.();
    }
}

let aiWebSocket: AiAnalyseSocket;

const setNewAiWebSocket = (token = '') => {
    aiWebSocket = new AiAnalyseSocket(token);
    return aiWebSocket;
};

const getAiWebSocket = () => aiWebSocket;

export {
    aiWebSocket,
    setNewAiWebSocket,
    getAiWebSocket,
};

export default AiAnalyseSocket;