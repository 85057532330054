const LOCAL_SHOP_ACCOUNT_NAME = '__shop_account_name__';

const SHOP_ACCOUNT_NAME_KEY = 'shop-name';

const getLocalShopAccountName = () => {
  return window.localStorage.getItem(LOCAL_SHOP_ACCOUNT_NAME);
};

const setLocalShopAccountName = (shopAccountName: string) => {
  window.localStorage.setItem(LOCAL_SHOP_ACCOUNT_NAME, shopAccountName);
};

const clearLocalShopAccountName = () => {
  window.localStorage.removeItem(LOCAL_SHOP_ACCOUNT_NAME);
};

const COUNTRY_NAME_KEY = 'country-name';

const percentKeys = ['acos_14d_min', 'acos_14d_max', 'ctr_min', 'ctr_max', 'cvr_14d_min', 'cvr_14d_max'];

const removeNullProperties = (obj: Record<string, any>): Record<string, any> => {
  const entries = Object.entries(obj);
  const filteredEntries = entries.filter(([_, value]) => value !== null);
  return Object.fromEntries(filteredEntries);
};
/**
 * 将百分比字段转换为百分比数字
 * @param obj 
 * @returns 
 */
const convertPercentKeys = (obj: Record<string, any>): Record<string, any> => {
  const newObj = { ...obj };
  percentKeys.forEach(key => {
    if (newObj[key]) {
      newObj[key] = newObj[key] * 100;
    }
  });
  return newObj;
}

export {
  getLocalShopAccountName,
  setLocalShopAccountName,
  SHOP_ACCOUNT_NAME_KEY,
  COUNTRY_NAME_KEY,
  clearLocalShopAccountName,
  removeNullProperties,
  convertPercentKeys,
};
